import React from 'react';

import Accessibility from './accessibility';

const Wrapper = ({ children, location }) => (
  <>
    {children}
    <Accessibility location={location} />
  </>
);

export default Wrapper;