import React, { useEffect, useState } from "react"
import * as access from "./accessibility.module.css"
import logo from "../images/accessibility-icon.svg"
import cursor from "../images/cursor.svg"
import { X } from "react-feather"

function Accessibility({ location }) {
    const [settings, setSettings] = useState({
        contrast: false,
        textSize: false,
        cursor: false,
        dyslexiaFont: false,
    });

    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

    useEffect(() => {
        window.addEventListener('mousemove', (event) => {
            setMousePos({ x: event.clientX, y: event.clientY });
        });
        if(settings.contrast) {
            document.documentElement.classList.add('invertColors');
        } else {
            document.documentElement.classList.remove('invertColors')
        }
        if(settings.textSize) {
            document.documentElement.classList.add('enlargeText');
        } else {
            document.documentElement.classList.remove('enlargeText')
        }
        if(settings.cursor) {
            const elements = document.querySelectorAll('body *');
            elements.forEach((element) => {
                element.classList.add('hideCursor');
            });
            document.documentElement.classList.add('bigCursor');
        } else {
            const elements = document.querySelectorAll('body *');
            elements.forEach((element) => {
                element.classList.remove('hideCursor');
            });
            document.documentElement.classList.remove('bigCursor');
        }
        if(settings.dyslexiaFont) {
            const elements = document.querySelectorAll('body *');
            elements.forEach((element) => {
                element.classList.add('dyslexiaFriendly');
            });
        } else {
            const elements = document.querySelectorAll('body *');
            elements.forEach((element) => {
                element.classList.remove('dyslexiaFriendly');
            });
        }
    },[settings, location]);

    const [panel, setPanel] = useState(false);

    const toggleSetting = (key) => {
        if(key === 'contrast') {
            setSettings({...settings, contrast: !settings.contrast});
        }
        if(key === 'textSize') {
            setSettings({...settings, textSize: !settings.textSize});
        }
        if(key === 'cursor') {
            setSettings({...settings, cursor: !settings.cursor});
        }
        if(key === 'dyslexiaFont') {
            setSettings({...settings, dyslexiaFont: !settings.dyslexiaFont});
        }
    }

    return (
        <>
      <div className={access.button}>
        <div className={access.icon} onClick={()=>setPanel(!panel)}><img src={logo} alt={"Accessibility"} /></div>
      </div>
      {panel && <div className={access.settings}>
        <div className={access.header}>
            <p>Accessbility Settings</p>
            <div className={access.close} onClick={()=>setPanel(false)}><X /></div>
        </div>
        <div className={access.control}>
            <p>Invert colors</p>
            <div className={`${access.toggle} ${settings.contrast && access.on}`} onClick={() => toggleSetting('contrast')}>
                <div className={access.state}></div>
            </div>
        </div>
        <div className={access.control}>
            <p>Large text</p>
            <div className={`${access.toggle} ${settings.textSize && access.on}`} onClick={() => toggleSetting('textSize')}>
                <div className={access.state}></div>
            </div>
        </div>
        <div className={`${access.control} ${access.notHover}`}>
            <p>Large cursor</p>
            <div className={`${access.toggle} ${settings.cursor && access.on}`} onClick={() => toggleSetting('cursor')}>
                <div className={access.state}></div>
            </div>
        </div>
        <div className={access.control}>
            <p>Dyslexia friendly</p>
            <div className={`${access.toggle} ${settings.dyslexiaFont && access.on}`} onClick={() => toggleSetting('dyslexiaFont')}>
                <div className={access.state}></div>
            </div>
        </div>
      </div>}
      <div className={`${access.bigCursor} customCursor`} style={{top: mousePos.y, left: mousePos.x}}>
        <img src={cursor} alt={'Big cursor'} />
      </div>
      </>
    )
  }
  
  export default Accessibility
  