// extracted by mini-css-extract-plugin
export var bigCursor = "accessibility-module--bigCursor--737e7";
export var button = "accessibility-module--button--c1b71";
export var close = "accessibility-module--close--220f9";
export var control = "accessibility-module--control--31fa1";
export var header = "accessibility-module--header--08d26";
export var icon = "accessibility-module--icon--ea11d";
export var notHover = "accessibility-module--notHover--8dcfd";
export var on = "accessibility-module--on--8cbdc";
export var settings = "accessibility-module--settings--e1d8e";
export var state = "accessibility-module--state--ab43e";
export var toggle = "accessibility-module--toggle--ef0b8";